import { tns } from 'tiny-slider';

import { qs } from '../dom-helpers';

class PlatformsSlider {
  constructor() {
    if (!qs('.slider__list')) return;
    this._run();
  }

  _run() {
    try {
      this._registerSlider();
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  _registerSlider() {
    tns({
      container: '.slider__list',
      slideBy: 1,
      items: 3,
      speed: 500,
      // fixedWidth: 370,
      fixedWidth: 300,
      edgePadding: 15,
      gutter: 16,
      controls: false,
      nav: false,
      autoplayButton: false,
      mouseDrag: true,
      swipeAngle: false,
      autoplay: false,
      loop: false,
      navAsThumbnails: true,
      center: true,
      responsive: {
        576: {
          fixedWidth: 370,
          edgePadding: (window.innerWidth - 540) / 2,
          center: false,
        },
        768: {
          edgePadding: (window.innerWidth - 720) / 2,
        },
        992: {
          edgePadding: (window.innerWidth - 976) / 2,
        },
        1200: {
          fixedWidth: 389,
          edgePadding: (window.innerWidth - 1200) / 2,
        },
      },
    });
  }
}

export default PlatformsSlider;
